import { getThemeByProductCodePathFromSiteType } from "../../themes/constants";
import { DIGITAL_WEDDING_WEBSITE_PK } from "../../website/constants";
import { getDesign } from "../../website/utils";
import axios from "axios";
import config from "config";
import { useState, useEffect } from "react";
import urljoin from "url-join";

const couplePhotoDefault = require("../../website/images/website/photo-couple.png");

const getImageUrl = (colorwayCode, colorways) => {
  // Returns the default image for online invitation sites, which we retrieve from the response from Dekura
  const colorway = colorways.find((cw) => cw.colorway === colorwayCode);

  if (colorway && colorway.required && colorway.required.artwork) {
    return (
      colorway.required.artwork.MobileLargeGraphic ||
      colorway.required.artwork.LargeGraphic
    );
  }

  return null;
};

const useSiteImage = (site) => {
  const [siteImage, setSiteImage] = useState(
    site.savedDesignImage?.preview || site.previewUrl || "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColorwayBackgroundColor, setSelectedColorwayBackgroundColor] =
    useState("");

  useEffect(() => {
    if (site.isWedding && !site.previewUrl) {
      setIsLoading(true);
      getDesign(site.mintedProductSku)
        .then((results) => {
          const colorways = results.products.find(
            (product) => product.kind.code === DIGITAL_WEDDING_WEBSITE_PK,
          ).colorways;
          const colorway = colorways.find(
            (colorway) =>
              colorway.externalId === site.mintedProductColorChoiceId,
          );

          setSiteImage(colorway.images.pz);
        })
        .catch(() => {
          setSiteImage(couplePhotoDefault);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (site.isOnlineInvitation) {
      const dekuraUrl = config.get("dekuraUrl");

      setIsLoading(true);
      axios(
        urljoin(
          dekuraUrl,
          getThemeByProductCodePathFromSiteType(site.siteType),
          site.mintedProductSku,
          "/",
        ),
        {
          timeout: 5000,
        },
      )
        .then((response) => {
          const data = response.data.data;
          const design = response.data.design;
          const selectedColorwayCode = design.colorways.find(
            (colorway) =>
              colorway.external_id === site.mintedProductColorChoiceId,
          ).code;

          const selectedColorwayData = data.colorways.find(
            (colorway) => colorway.colorway === selectedColorwayCode,
          );

          setSelectedColorwayBackgroundColor(
            selectedColorwayData.required.colors.backgroundColor,
          );

          const imageUrl = getImageUrl(selectedColorwayCode, data.colorways);

          setSiteImage(imageUrl);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    site.isOnlineInvitation,
    site.isWedding,
    site.mintedProductColorChoiceId,
    site.mintedProductSku,
    site.siteType,
    site.previewUrl,
  ]);

  return [siteImage, isLoading, selectedColorwayBackgroundColor];
};

export default useSiteImage;

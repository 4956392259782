import SiteFooter from "../../core/dashboard/SiteFooter";
import NavigationContext from "../../navigation/dashboard/NavigationContext";
import SiteNavigation from "../../navigation/dashboard/SiteNavigation";
import SiteSection from "./SiteSection";
import { css } from "@emotion/react";
import {
  styleUtils,
  theme,
  useMediaQueryState,
  Alert,
} from "@minted/minted-components";
import partition from "lodash/partition";
import PropTypes from "prop-types";
import { React, useState, useCallback } from "react";

const sitePickerStyles = {
  background: css`
    background-color: ${theme.colors.gray100};
  `,
  wrapper: (isMobile) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${theme.spacing.x4};
    padding-left: ${styleUtils.rem(isMobile ? 20 : 70)};
    padding-right: ${styleUtils.rem(isMobile ? 20 : 70)};
  `,
};

const sectionTypes = ["active", "archived", "deleted"];

const SitePicker = ({ navBarProps, sites }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const orderedSites = partition(sites, (site) => site.isWedding).flat(1);

  const clearAlert = useCallback(() => {
    setErrorMessage("");
  }, []);

  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: "medium",
  });
  const isMobile = mediumMediaQueryState === "BELOW";

  return (
    <div css={sitePickerStyles.background}>
      <NavigationContext.Provider value={navBarProps}>
        <SiteNavigation isMultisiteDashboard />
      </NavigationContext.Provider>

      <Alert
        onCloseClick={clearAlert}
        secondaryText={errorMessage}
        show={Boolean(errorMessage)}
        type={Alert.types.negative}
      />
      <div css={sitePickerStyles.wrapper(isMobile)}>
        {sectionTypes.map((sectionType) => (
          <SiteSection
            key={sectionType}
            sectionType={sectionType}
            setErrorMessage={setErrorMessage}
            sites={orderedSites}
          />
        ))}
      </div>
      <SiteFooter />
    </div>
  );
};

SitePicker.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SitePicker;
